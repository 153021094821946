import React, { useEffect, useState } from "react";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, SearchBox, RefinementList } from "react-instantsearch";
import StaticPageContainer from "../../components/static-page/static-page";
import { Box, useMediaQuery } from "@mui/material";
import CustomFilterWidget from "../../components/filter/filter-widget";
import { singleIndex } from "instantsearch.js/es/lib/stateMappings";
import PropTypes from "prop-types";
import "./faq.scss";
import CustomFilterButton from "../../components/filter/filter-button";
import ScrollToTopButton from "../../components/scroll-to-top/scroll-to-top-button";
import FaqCustomHits from "./faq-custom-hits";

const appId = process.env.REACT_APP_ALGOLIA_APP_ID;
const apiKey = process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY;
const searchClient = algoliasearch(appId, apiKey);

function FAQ(props) {
  const isDesktop = useMediaQuery("(min-width: 768px)");

  const [isFilterOpen, setIsFilterOpen] = useState(isDesktop);
  const [hits, setHits] = useState([]);
  const [isQueryEmpty, setIsQueryEmpty] = useState(true);

  useEffect(() => {
    if (!isQueryEmpty) {
      return;
    }

    // Initial scroll on page load
    if (window.location.hash && hits.length > 0) {
      scrollToQuestion();
    }

    // Listen to hash changes
    window.addEventListener("hashchange", scrollToQuestion);
    return () => {
      window.removeEventListener("hashchange", scrollToQuestion);
    };
  }, [hits, isQueryEmpty]);

  const scrollToQuestion = () => {
    const elementId = window.location.hash.replace("#", "");
    const navbarHeight = document.querySelector("header")?.offsetHeight || 0;

    // Find the element by ID and scroll to it
    const targetElement = document.getElementById(elementId);
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop - navbarHeight - 20,
        behavior: "smooth",
      });
    }
  };

  const filterColumns = [
    {
      filterColumnName: "Filter By Topic",
      refinementListComponent: (
        <RefinementList attribute="typeName" limit={15} showMore showMoreLimit={100} sortBy={["name:asc"]} />
      ),
    },
    {
      filterColumnName: "Filter By Division",
      refinementListComponent: <RefinementList attribute="divisionNames" />,
    },
  ];

  return (
    <StaticPageContainer title={"Frequently Asked Questions"}>
      <ScrollToTopButton />

      <InstantSearch
        searchClient={searchClient}
        indexName={process.env.REACT_APP_ALGOLIA_FAQ_INDEX}
        routing={{
          stateMapping: singleIndex(`${process.env.REACT_APP_ALGOLIA_FAQ_INDEX}`),
        }}
        onStateChange={({ uiState, setUiState }) => {
          if (window.location.hash) {
            window.history.replaceState(
              null,
              document.title,
              window.location.pathname + window.location.search
            );
          }
          setUiState(uiState);
        }}
      >
        {/* Search */}
        <div className="faq-searchbox d-flex justify-content-center py-2">
          <SearchBox placeholder="Search FAQ" searchAsYouType />
        </div>

        {/* Filter */}
        <div className="pt-4 pb-3">
          <CustomFilterButton isFilterOpen={isFilterOpen} setIsFilterOpen={setIsFilterOpen} {...props} />
        </div>

        {/* Filter Widget & Results */}
        <div className={`d-flex ${isFilterOpen ? "gap-4" : "gap-0"}`}>
          <CustomFilterWidget
            filterColumns={filterColumns}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
            isDesktop={isDesktop}
            {...props}
          />

          <Box
            sx={{
              paddingTop: 2,
              flexBasis: 0,
              flexGrow: 1,
              transition: "width 0.3s ease",
              overflow: "hidden",
            }}
          >
            <FaqCustomHits setHits={setHits} setIsQueryEmpty={setIsQueryEmpty} {...props} />
          </Box>
        </div>
      </InstantSearch>
    </StaticPageContainer>
  );
}

FAQ.propTypes = {
  resultsState: PropTypes.object,
};

export default FAQ;
