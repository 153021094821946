import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, Card, Typography } from "@mui/material";
import { parseHtml } from "../../utils/utils";
import { Link } from "react-router-dom";
import StaticPageContainer from "../static-page/static-page";
import { getDataFromCmsApi } from "../../api/contentful-service";
import Loader from "../loader";


const AboutDivision = ({ divisionAbbreviation, linkToDivisionHome }) => {
  const [division, setDivision] = useState([]);
  const [chiefDeputy, setChiefDeputy] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getData().then(() => setIsLoading(false));
  }, []);

  const getData = async () => {
    const division = await getDataFromCmsApi(`Division/${divisionAbbreviation}`);
    setDivision(division);

    const chiefDeputy = await getDataFromCmsApi(`TeamMember/${divisionAbbreviation}`);
    setChiefDeputy(chiefDeputy[0]);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <StaticPageContainer title={`About ${division.divisionName}`}>
      <div className="mb-4">
        <Card
          elevation={0}
          sx={{
            border: "none",
            fontSize: { xs: "medium", sm: "large" },
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#cd9e0c21", // Add a light color background
            padding: "20px", // Add padding for spacing
          }}
        >
          <Typography variant="h4" mb={2} className="text-center">
            Chief Deputy 
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", flexDirection: { xs: "column", lg: "row" } }}>
            <div className="d-flex flex-column justify-content-center">
              <img
                src={chiefDeputy.imageUrl}
                alt={`${chiefDeputy.firstName} ${chiefDeputy.lastName}`}
                style={{
                  objectFit: "contain",
                  width: "250px",
                  margin: "30px",
                  borderRadius: "25% 0 25% 0",
                }}
              />
              <div>
                <Typography variant="h6" className="text-center fw-bold">
                  {chiefDeputy.firstName} {chiefDeputy.lastName}
                </Typography>
                <Typography variant="subtitle1" mb={2} className="text-center">
                  {chiefDeputy.position}
                </Typography>
              </div>
            </div>
            <blockquote className="blockquote mt-3 mb-5 mx-4">{parseHtml(chiefDeputy.biography)}</blockquote>
          </Box>
        </Card>
      </div>
      <div>
        <Typography variant="h4" py={2}>
       
        </Typography>
        <Card
          variant="outlined"
          sx={{
            border: "none",
            fontSize: { xs: "medium", sm: "large" },
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="mb-5">
            <blockquote className="blockquote" style={{ textAlign: "justify" }}>
              {parseHtml(division.divisionDescription)}
            </blockquote>
          </div>
        </Card>
        <div className="d-flex justify-content-center">
          <Button variant="contained" component={Link} to={linkToDivisionHome}>
            Return to {division.divisionName} Home
          </Button>
        </div>
      </div>
    </StaticPageContainer>
  );
};

AboutDivision.propTypes = {
  linkToDivisionHome: PropTypes.string.isRequired,
  divisionAbbreviation: PropTypes.string.isRequired,
};

export default AboutDivision;

