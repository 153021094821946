export const loadGSGScript = (scriptSource, widgetName) => {
    return new Promise((resolve, reject) => {
        let isGSGScriptLoaded = false;
        // Check if script is already loaded
        if (isGSGScriptLoaded) {
            waitForGSGWidget(widgetName, resolve, reject); // Wait for GSGWidget to initialize
            return;
        }

        // Check if the script tag already exists
        const existingScript = document.querySelector(`script[src="${scriptSource}"]`);
        if (existingScript) {
            isGSGScriptLoaded = true;
            waitForGSGWidget(widgetName, resolve, reject); // Wait for GSGWidget to initialize
            return;
        }

        // Create and append the script
        const script = document.createElement('script');
        script.src = scriptSource;
        script.type = 'text/javascript';
        script.charset = 'utf-8';
        script.onload = () => {
            isGSGScriptLoaded = true;
            waitForGSGWidget(widgetName, resolve, reject); // Wait for GSGWidget to initialize
        };
        script.onerror = (error) => reject(error);
        document.head.appendChild(script);
    });
};

// Utility function to wait for GSGWidget initialization
const waitForGSGWidget = (widgetName, resolve, reject) => {
    const maxRetries = 20; // Number of times to check
    const interval = 100; // Milliseconds between checks
    let retries = 0;

    const checkWidget = () => {
        const widgetInstance = window[widgetName]; // Dynamically check for the widget instance
        if (widgetInstance) {
            resolve(widgetInstance); // Widget is initialized
        } else if (retries < maxRetries) {
            retries++;
            setTimeout(checkWidget, interval); // Retry after interval
        } else {
            reject(new Error('GSGWidget failed to initialize within the expected time.'));
        }
    };

    checkWidget();
};
