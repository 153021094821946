import React, { useEffect, useState } from "react";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../utils/utils";
import InformationalTiles from "../../components/informational-tiles";
import StaticPageContainer from "../../components/static-page/static-page";

function AwardsAndAccolades() {
  const [awards, setAwards] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi("webpage/awardsAccolades");
    setAwards(result);
  };

  return (
    awards && (
      <StaticPageContainer title={awards.pageTitle}>
        {awards.webpageItems &&
          awards.webpageItems.map((award, index) => (
            <div key={index}>
              <InformationalTiles
                icon={award.imageUrl}
                title={award.title}
                content={parseHtml(award.description)}
                secondaryIcon={award.secondaryImageUrl}
                secondaryContent={award.secondaryDescription}
                side={"right"}
              />
            </div>
          ))}
      </StaticPageContainer>
    )
  );
}
export default AwardsAndAccolades;
