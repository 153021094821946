
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from '../breadcrumbs'
import { getDataFromCmsApi } from '../../api/contentful-service'
import WelcomeSection from './welcome'

const DefaultLayout = ({ title, description, divisionTag, children }) => {
    const [backgroundImage, setBackgroundImage] = React.useState()

    useEffect(() => {
        getImage();
    }, [divisionTag]);

    const getImage = async () => {
        const querystring = `tags=${divisionTag}&tags=layoutHeaderImage`;
        const image = await getDataFromCmsApi(`asset/tags?${querystring}`);
        
        let filteredImage = image.filter((item) => 
            item.tags.includes(divisionTag) && item.tags.includes("layoutHeaderImage")
        );
    
        if (filteredImage.length === 0) {
            filteredImage = image.filter((item) => 
                item.tags.includes("default") && item.tags.includes("layoutHeaderImage")
            );
        }
    
        setBackgroundImage(filteredImage[0]);
    };

    return (
        <WelcomeSection title={title} description={description || "Auditor-Controller/Treasurer/Tax Collector"}
            backgroundImageUrl={backgroundImage && backgroundImage.fileUrl}>
            <Breadcrumbs />
            <div className="mt-2">
                {children}
            </div>
        </WelcomeSection>
    )
}

DefaultLayout.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    children: PropTypes.node.isRequired,
    divisionTag: PropTypes.string
}

export default DefaultLayout