import React, { useEffect, useState } from "react";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../utils/utils";
import PropTypes from "prop-types";
import Bio from "../../components/bio/bio";
import AlertService from "../../components/alert-service/alert-service";

const contentTypeBio = "TeamMember";
const contentTypeDiv = "Division";

function AdminHome({ division }) {
  const [chiefBio, setChiefBios] = useState([]);
  const [divDesc, setDivisionDescription] = useState([]);

  useEffect(() => {
    getBioData();
    getDivData();
  }, []);

  const getBioData = async () => {
    const result = await getDataFromCmsApi(`${contentTypeBio}/${division.divisionAbbreviation}`);

    const chiefBios = result
      .map((item) => {
        return {
          welcomeMessage: parseHtml(item.welcomeMessage),
          firstName: item.firstName,
          lastName: item.lastName,
          title: item.title,
          position: item.position,
          suffix: item.suffix,
          image: "https:" + item.imageUrl,
          biography: parseHtml(item.biography),
          sortOrder: item.sortOrder,
        };
      })
      .sort((a, b) => a.sortOrder - b.sortOrder);

    setChiefBios(chiefBios);
  };

  const getDivData = async () => {
    const result = await getDataFromCmsApi(`${contentTypeDiv}/${division.divisionAbbreviation}`);

    const divsionInfo = {
      divisionAbbreviation: result.divisionAbbreviation,
      divisionName: result.divisionName,
      divisionDescription: parseHtml(result.divisionDescription),
    };
    setDivisionDescription(divsionInfo);
  };

  return (
    <div>
      <div className="container mb-4">
        <div className="mb-4">
          <AlertService divisionCode={divDesc.divisionAbbreviation ? divDesc.divisionAbbreviation : ""} />
        </div>
      </div>
      
      <div className="d-flex justify-content-center align-items-center content">
        <div className="col-9">
          {chiefBio.map((item, index) => (
            <div key={index}>
              {item.welcomeMessage}
              <div className={index % 2 === 0 ? "LeftBioSection" : "RightBio"}>
                <Bio
                  imgPositionLeft={index % 2 === 0 ? true : false}
                  subTitle={item.position}
                  imgSrc={item.image}
                  title={`${item.firstName} ${item.lastName} ${item.suffix ? item.suffix : ""}`}
                  description={item.biography}
                ></Bio>
              </div>
            </div>
          ))}
          <div className="lead py-5 mb-5">{divDesc.divisionDescription}</div>
        </div>
      </div>
    </div>
  );
}

AdminHome.propTypes = {
  division: PropTypes.shape({
    divisionAbbreviation: PropTypes.string.isRequired,
  }).isRequired,
};

export default AdminHome;
