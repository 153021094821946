import Navigation from "../nav-bar/navigation";
import Footer from "../footer/footer";
import PropTypes from "prop-types";
import EnvBanner from "../env-banner/env-banner";

function Layout({ children }) {

  return (
    <>
      <div className="main">
        <EnvBanner />
        <Navigation />
        {children}
        <Footer />
      </div>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
