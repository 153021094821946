import { useEffect, useState } from "react";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../utils/utils";
import StaticPage from "../../components/static-page/static-page";
import "./index.scss";

const TaxCollectorClosureSchedule = () => {
  const [taxCollectorClosureHours, setTaxCollectorClosureHours] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi("webpage/taxCollectorClosureSchedule");
    setTaxCollectorClosureHours(result);
  };

  return (
    taxCollectorClosureHours && (
      <StaticPage title={taxCollectorClosureHours.pageTitle}>
        <div className="closure-hours-table d-flex flex-column align-items-center">
          {parseHtml(taxCollectorClosureHours.content)}
        </div>
      </StaticPage>
    )
  );
};

export default TaxCollectorClosureSchedule;
