import React from "react";
import PropTypes from "prop-types";
import CustomFilterNumber from "./filter-number";
import { FilterList } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useClearRefinements } from "react-instantsearch-core";

const CustomFilterButton = ({ isFilterOpen, setIsFilterOpen, ...props }) => {
  const { refine, canRefine } = useClearRefinements();

  return (
    <div className="d-flex justify-content-center justify-content-sm-start column-gap-2">
      <Button
        variant={isFilterOpen ? "contained" : "text"}
        startIcon={<FilterList />}
        endIcon={<CustomFilterNumber isFilterOpen={isFilterOpen} {...props} />}
        onClick={() => {
          setIsFilterOpen(!isFilterOpen);
        }}
      >
        Filter
      </Button>

      <Button onClick={refine} disabled={!canRefine}>
        Clear Filters
      </Button>
    </div>
  );
};

CustomFilterButton.propTypes = {
  isFilterOpen: PropTypes.bool.isRequired,
  setIsFilterOpen: PropTypes.func.isRequired,
};

export default CustomFilterButton;
