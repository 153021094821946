import React, { useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import { useHits } from "react-instantsearch";
import ViewFileButtons from "./view-file-buttons";
import PropTypes from "prop-types";

function CustomCardHits({ isFilterOpen, ...props }) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  const { results } = useHits(props);
  if (!results) return null;
  const { hits } = results;

  const totalPages = Math.ceil(hits.length / itemsPerPage);
  const paginatedHits = hits.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <>
      {paginatedHits.length === 0 ? (
        <div className="d-flex flex-column justify-content-center align-items-center gap-3 py-5">
          <CircularProgress /> <h5>Loading reports...</h5>
        </div>
      ) : (
        <>
          <Grid container spacing={3}>
            {paginatedHits.map((hit, i) => (
              <Grid item xs={12} md={isFilterOpen ? 12 : 6} lg={6} key={i}>
                <Card className="d-flex justify-content-between h-100">
                  <CardMedia
                    component="img"
                    sx={{ width: 160 }}
                    image="/assets/images/atc5.jpg"
                    alt={hit.report_name}
                  />

                  <CardContent sx={{ width: "100%", padding: "18px 20px" }}>
                    <Typography variant="body">{hit.report_name}</Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                      {hit.report_category}
                    </Typography>
                    <div className="pt-1">
                      <Chip label={hit.division} size="small" />
                    </div>
                  </CardContent>

                  <CardActions className="me-3">
                    <ViewFileButtons hit={hit} />
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            sx={{ marginTop: "20px", display: "flex", justifyContent: "center" }}
          />
        </>
      )}
    </>
  );
}

CustomCardHits.propTypes = {
  isFilterOpen: PropTypes.bool,
};

export default CustomCardHits;
