import { useEffect, useState } from "react";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { isExternalLink, parseHtml } from "../../utils/utils";
import StaticPage from "../../components/static-page/static-page";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const ReportFraudWasteAbuse = () => {
  const [reportFraudWasteAbuse, setReportFraudWasteAbuse] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi("webpage/reportFraudWasteAbuse");
    setReportFraudWasteAbuse(result);
  };

  return (
    reportFraudWasteAbuse && (
      <StaticPage title={reportFraudWasteAbuse.pageTitle}>
        {parseHtml(reportFraudWasteAbuse.content)}
        {reportFraudWasteAbuse.webpageItems &&
          reportFraudWasteAbuse.webpageItems.map((item, index) => (
            <div className="text-center text-sm-start" key={index}>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={item.link}
                target={isExternalLink(item.link) ? "_blank" : ""}
                sx={{ textAlign: "center" }}
              >
                {item.title}
              </Button>
            </div>
          ))}
      </StaticPage>
    )
  );
};

export default ReportFraudWasteAbuse;
