import { useEffect, useState } from "react";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../utils/utils";
import StaticPage from "../../components/static-page/static-page";
import InformationalTiles from "../../components/informational-tiles";

const ControllerAwards = () => {
  const [controllerAwards, setControllerAwards] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi("webpage/controllerAwards");
    setControllerAwards(result);
  };

  return (
    controllerAwards && (
      <StaticPage title={controllerAwards.pageTitle}>
        {controllerAwards.webpageItems &&
          controllerAwards.webpageItems.map((award, index) => (
            <div key={index}>
              <InformationalTiles
                icon={award.imageUrl}
                title={award.title}
                content={parseHtml(award.description)}
                secondaryIcon={award.secondaryImageUrl}
                secondaryContent={award.secondaryDescription}
                side={"right"}
              />
            </div>
          ))}
      </StaticPage>
    )
  );
};

export default ControllerAwards;
