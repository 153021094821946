import React from "react";
import DivisionServices from "../../components/division-homepage/division-services";
import DivisionAboutBanner from "../../components/division-homepage/division-about-banner";
import DivisionContact from "../../components/division-homepage/division-contact";
import AlertService from "../../components/alert-service/alert-service";
import PropTypes from "prop-types";

function ITHome({ division }) {
    return (
        <>
            <div className="container">
                <AlertService divisionCode={division.divisionAbbreviation} />
                <DivisionServices divisionAbbreviation={division.divisionAbbreviation} />
            </div>
            <div>
                <DivisionAboutBanner
                    divisionAbbreviation={division.divisionAbbreviation}
                    divisionTag={division.tag}
                    linkTo="/information-technology/about"
                />
                <DivisionContact
                    divisionAbbreviation={division.divisionAbbreviation}
                    sectionTitle={"Contact ITD"}
                    formLink="/"
                />
            </div>
        </>
    );
}

ITHome.propTypes = {
    division: PropTypes.shape({
        divisionAbbreviation: PropTypes.string.isRequired,
        tag: PropTypes.string.isRequired,
    }).isRequired,
};

export default ITHome;
