import { useState } from "react";
import { CssBaseline, Divider, Drawer, IconButton, List } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { divisionItemsList } from "../../utils/division-items";
import { onlineServiceItemsList } from "../../utils/online-services-items";
import { generalInfoItemsList } from "../../utils/general-info-items";
import { navbarList } from "../../utils/navbar-items";
import LoginButtonContainer from "../../containers/login-button-container";
import { MenuSubHeader, CustomCollapse } from "./navbar-items";
import IconNavContainer from "./icon-nav-container";
import NavbarDrawerHeader from "./navbar-drawer-header";

export default function PersistentDrawerLeft() {
  const [open, setOpen] = useState(false);
  const [openOnlineServices, setOnlineServicesOpen] = useState(false);
  const [openDivisions, setDivisionsOpen] = useState(false);
  const [openGeneralInfo, setGeneralInfoOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleDivisions = () => {
    setDivisionsOpen(!openDivisions);
  };
  const handleOnlineServices = () => {
    setOnlineServicesOpen(!openOnlineServices);
  };
  const handleGeneralInfo = () => {
    setGeneralInfoOpen(!openGeneralInfo);
  };

  return (
    <>
      <CssBaseline />
      <div className="nav-logo-wrapper">
        <IconButton className="gap-3" color="inherit" aria-label="open drawer" onClick={handleDrawerOpen}>
          <Menu />
          <img className="nav-logo" src="/assets/logos/sb-logo-full-color.png" alt="SBC ATC Logo" />
        </IconButton>
        <IconNavContainer />
      </div>

      <Drawer
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
        sx={{ zIndex: 10000 }}
        PaperProps={{
          sx: {
            backgroundColor: "white",
            maxWidth: "400px",
            width: "100%",
          },
        }}
      >
        <NavbarDrawerHeader handleDrawerClose={handleDrawerClose}>
          <img className="h-100" src="/assets/logos/sb-logo-full-color.png" alt="SBC ATC Logo" />
        </NavbarDrawerHeader>

        <div className="d-flex flex-column justify-content-between h-100">
          <List sx={{ px: 2 }}>
            {navbarList.map((item, i) => (
              <div key={i}>
                <MenuSubHeader
                  title={item.subheader}
                  icon={item.icon}
                  to={item.subheaderLink}
                  onClick={() => {
                    item.subheader === "Online Services"
                      ? handleOnlineServices()
                      : item.subheader === "Divisions"
                      ? handleDivisions()
                      : item.subheader === "General Information"
                      ? handleGeneralInfo()
                      : handleDrawerClose();
                  }}
                  openDivisions={openDivisions}
                  openOnlineServices={openOnlineServices}
                  openGeneralInfo={openGeneralInfo}
                />

                {item.subheader === "Online Services" && (
                  <CustomCollapse
                    isOpen={openOnlineServices}
                    list={onlineServiceItemsList}
                    onClick={handleDrawerClose}
                  />
                )}

                {item.subheader === "Divisions" && (
                  <CustomCollapse
                    isOpen={openDivisions}
                    list={divisionItemsList}
                    onClick={handleDrawerClose}
                  />
                )}

                {item.subheader === "General Information" && (
                  <CustomCollapse
                    isOpen={openGeneralInfo}
                    list={generalInfoItemsList}
                    onClick={handleDrawerClose}
                  />
                )}

                <Divider />
              </div>
            ))}
            <LoginButtonContainer isDrawer={true} handleDrawerClose={handleDrawerClose} />
          </List>
        </div>
      </Drawer>
    </>
  );
}
