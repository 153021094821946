import { getDataFromCmsApi } from "../api/contentful-service";
import DynamicPage from "../components/dynamic-page";
import JotFormContainer from "../components/jotform-container";
import DivisionHomepageLayout from "../components/layouts/division-homepage-layout";

const fetchDynamicRoutes = async (divisionCode) => {
  try {
    const result = await getDataFromCmsApi(`divisionService/${divisionCode}`);

    const mappedRoutes = result.map((route) => {
      // Check if the route corresponds to "Contact Us"
      if (route.link === "/contact-us") {
        return {
          path: "/:formIdentifier",
          component: <JotFormContainer pageTitle="Contact Us" />,
          meta: { title: "Contact Us", description: "Get in touch with us." },
        };
      }

      return {
        path: route.link || "*",
        component: (
          <DivisionHomepageLayout
            division={{
              tag: divisionCode.toLowerCase(),
              title: `${divisionCode} Division`,
              divisionAbbreviation: divisionCode,
            }}
          >
            <DynamicPage webpageId={route.webpage?.pageId} parentServiceId={route.id} />
          </DivisionHomepageLayout>
        ),
        meta: { title: route.title, description: route.description },
      };
    });

    // console.log(`Mapped routes for ${divisionCode}:`, mappedRoutes);
    return mappedRoutes;
  } catch (error) {
    console.error(`Error fetching routes for ${divisionCode}`, error);
    return [];
  }
};

export default fetchDynamicRoutes;
