import React, { useEffect } from "react";
import PropTypes from "prop-types";
import scrollToTop from "../../utils/scroll-to-top-util";

function ScrollToTop({ children }) {
  useEffect(() => {
    scrollToTop();
  });

  return <>{children}</>;
}

ScrollToTop.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default ScrollToTop;
