import { Button } from '@mui/material'
import React from 'react'
import { PropTypes } from 'prop-types'

const CustomCookieButton = ({ id, ...props }) => {
    return (
        <Button
            variant={(id === 'rcc-decline-button' ? 'outlined' : 'contained')}
            color="primary" {...props}
            sx={{ margin: '8px' }} />
    )
}

CustomCookieButton.propTypes = {
    id: PropTypes.string.isRequired,
}

export default CustomCookieButton