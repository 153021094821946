import React, { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';
import { IoArrowUp } from 'react-icons/io5';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.scrollY > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (!isVisible) return null;

  return (
    <IconButton
      onClick={scrollToTop}
      sx={{
        position: 'fixed',
        bottom: '2rem',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000,
        color: 'white',
        backgroundColor: '#292929c4',
        '&:hover': {
          backgroundColor: '#292929f0',
        },
      }}
    >
      <IoArrowUp />
    </IconButton>
  );
};

export default ScrollToTopButton;