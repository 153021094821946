import { useEffect, useState } from "react";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../utils/utils";
import StaticPage from "../../components/static-page/static-page";

const TermsAndConditions = () => {
  const [termsAndConditions, setTermsAndConditions] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi("webpage/taxSalesTermsConditions");
    setTermsAndConditions(result);
  };

  return (
    termsAndConditions && (
      <StaticPage title={termsAndConditions.pageTitle}>{parseHtml(termsAndConditions.content)}</StaticPage>
    )
  );
};

export default TermsAndConditions;
