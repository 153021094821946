import React, { useEffect, useState } from "react";
import Bio from "../../components/bio/bio";
import StaticPageContainer from "../../components/static-page/static-page";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../utils/utils";

const About = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi("webpage/aboutAtc");
    setData(result);
  };

  return (
    <div className="container">
      {data && (
        <StaticPageContainer title={data.pageTitle} width="large">
          {data.webpageItems &&
            data.webpageItems.map((item, i) => (
              <Bio
                key={i}
                imgPositionLeft={i % 2 === 0 ? true : false}
                imgSrc={item.imageUrl}
                title={item.title}
                description={parseHtml(item.description)}
              />
            ))}
        </StaticPageContainer>
      )}
      <div className="pb-5 my-5">
        <h3 className="text-center display-6 mb-5"> ATC History: A Look Back In Time 1853 - Present </h3>
        <iframe
          title="atc history"
          src="https://cdn.knightlab.com/libs/timeline3/latest/embed/index.html?source=1BJ3JbgXLnt2UNCyMWp4QCiD7K0y2VJlh39TThbhLmSU&font=Default&lang=en&start_at_end=true&initial_zoom=2&height=650"
          width="100%"
          height="650"
          allowFullScreen
          frameBorder="0"
        ></iframe>
      </div>
    </div>
  );
};

export default About;
