import { Typography } from "@mui/material";
import React from "react";
import DowJones from "../../components/treasurer/dow-jones";
import TreasuryYieldGraph from "../../components/treasurer/market-update";
import NewsTicker from "../../components/treasurer/news-ticker";
import DivisionAboutBanner from "../../components/division-homepage/division-about-banner";
import DivisionContact from "../../components/division-homepage/division-contact";
import AlertService from "../../components/alert-service/alert-service";
import PropTypes from "prop-types";
import DivisionServicesSmall from "../../components/division-homepage/division-services-small";

function TreasurerHome({ division }) {
    return (
        <div>
            <div className="container">
                <AlertService divisionCode={division.divisionAbbreviation} />
                <DivisionServicesSmall divisionAbbreviation={division.divisionAbbreviation} includeHomepage={true} />
            </div>

            <DivisionAboutBanner
                divisionAbbreviation={division.divisionAbbreviation}
                divisionTag={division.tag}
                linkTo='/treasurer/about'
            />

            <div className="container treasurer">
                <div className="row downjones-graph py-5">
                    <Typography variant="h4" mb={1} color="gray">
                        Dow Jones
                    </Typography>
                    <DowJones />
                </div>

                <div className="row py-5">
                    <Typography variant="h4" mb={1} color="gray">
                        Treasury Market Update
                    </Typography>
                    <TreasuryYieldGraph />
                </div>

                <DivisionContact 
                    divisionAbbreviation={division.divisionAbbreviation} 
                    sectionTitle={"Contact Treasurer"} 
                    hasForm={false} 
                />
            </div>
            <NewsTicker />
        </div>
    );
}

TreasurerHome.propTypes = {
    division: PropTypes.object.isRequired,
};

export default TreasurerHome;
