import React, { useEffect, useState } from 'react';
import AwardsContainer from '../../components/awards/awards-container';
import { getDataFromCmsApi } from '../../api/contentful-service';

const DisbursementsAwardsPage = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi('webpage/disbursementsAwards');
    setData(result);
  };

  return (
    data && (
      <div>
        <h1 className="display-5 text-center mb-lg-4 pb-5 text-break mt-4">{data.pageTitle}</h1>
        <AwardsContainer pageId={'disbursementsAwards'} />
      </div>
    )
  );
};

export default DisbursementsAwardsPage;
