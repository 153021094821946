import { Box, Button, Collapse, SwipeableDrawer, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import NavbarDrawerHeader from "../nav-bar/navbar-drawer-header";
import { FilterList } from "@mui/icons-material";
import { useClearRefinements } from "react-instantsearch-core";
import CustomFilterNumber from "./filter-number";

const filterBackgroundColor = "#eef4fa";

const CustomFilterWidgetContent = ({ filterColumns, isDesktop }) => {
  return (
    <div className="h-100">
      <Box
        className={`d-grid row-gap-4 column-gap-5 py-3 ${isDesktop ? "px-3" : "px-4"}`}
        sx={{ gridAutoRows: "auto" }}
      >
        {filterColumns &&
          filterColumns.map((column, index) => (
            <div key={index} className="pb-2 w-100">
              <Typography variant="overline" component="h1" gutterBottom>
                {column.filterColumnName}
              </Typography>
              {column.refinementListComponent}
            </div>
          ))}
      </Box>
    </div>
  );
};

CustomFilterWidgetContent.propTypes = {
  filterColumns: PropTypes.array.isRequired,
  isDesktop: PropTypes.bool,
};

const CustomFilterWidget = ({ filterColumns, isFilterOpen, setIsFilterOpen = null, isDesktop }) => {
  const { refine, canRefine } = useClearRefinements();

  return isDesktop ? (
    <Collapse
      orientation="horizontal"
      in={isFilterOpen}
      sx={{
        backgroundColor: filterBackgroundColor,
        marginBottom: isFilterOpen ? 1 : 0,
        height: "fit-content",
        "& .MuiCollapse-wrapperInner": { width: "300px !important" },
      }}
    >
      <CustomFilterWidgetContent filterColumns={filterColumns} isDesktop={isDesktop} />
    </Collapse>
  ) : (
    <SwipeableDrawer
      anchor="left"
      open={isFilterOpen}
      onClose={() => setIsFilterOpen(false)}
      onOpen={() => setIsFilterOpen(true)}
      sx={{ zIndex: 10000 }}
      PaperProps={{
        sx: {
          backgroundColor: filterBackgroundColor,
          maxWidth: "350px",
          width: "100%",
        },
      }}
    >
      <NavbarDrawerHeader handleDrawerClose={() => setIsFilterOpen(false)}>
        <FilterList />
        {canRefine ? (
          <Button
            onClick={refine}
            hidden={!canRefine}
            variant="outlined"
            size="small"
            sx={{
              color: "white",
              borderColor: "white",
              "&:hover": {
                backgroundColor: "#002347",
              },
            }}
          >
            <span className="pe-2">Clear Filters</span>
            <CustomFilterNumber isFilterOpen={isFilterOpen} />
          </Button>
          // The Clear Filters button is solid in this version
          // <Button
          //   onClick={refine}
          //   hidden={!canRefine}
          //   variant="contained"
          //   size="small"
          //   sx={{
          //     color: "#003366",
          //     backgroundColor: "white",
          //     "&:hover": {
          //       backgroundColor: "#f5f7f9",
          //     },
          //   }}
          // >
          //   <span className="pe-2">Clear Filters</span>
          //   <CustomFilterNumber isFilterOpen={isFilterOpen} isColorInverted={true} />
          // </Button>
        ) : (
          <Typography variant="button">Filter</Typography>
        )}
      </NavbarDrawerHeader>
      <CustomFilterWidgetContent filterColumns={filterColumns} isDesktop={isDesktop} />
    </SwipeableDrawer>
  );
};

CustomFilterWidget.propTypes = {
  filterColumns: PropTypes.array.isRequired,
  isFilterOpen: PropTypes.bool.isRequired,
  setIsFilterOpen: PropTypes.func,
  isDesktop: PropTypes.bool.isRequired,
};

export default CustomFilterWidget;
