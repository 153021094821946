import React, { useEffect, useState } from "react";
import { Avatar, Button, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { CalendarMonth } from "@mui/icons-material";
import AlertService from "../../components/alert-service/alert-service";
import { getDataFromCmsApi } from "../../api/contentful-service";
import DivisionAboutBanner from "../../components/division-homepage/division-about-banner";
import DivisionContact from "../../components/division-homepage/division-contact";
import { Link } from "react-router-dom";
import { parseHtml } from "../../utils/utils";
import PropTypes from "prop-types";
import DivisionServicesSmall from "../../components/division-homepage/division-services-small";
import DivisionMoreServices from "../../components/division-homepage/division-more-services";
import "./index.scss";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const currentMonthIndex = new Date().getMonth();

const Home = ({ division }) => {
  const [importantDates, setImportantDates] = useState();

  useEffect(() => {
    getImportantDates();
  }, []);

  const getImportantDates = async () => {
    const result = await getDataFromCmsApi("ImportantDate");
    result.map((item) => {
      item.serviceDescription = parseHtml(item.serviceDescription);
      return item;
    });
    setImportantDates(result);
  };

  const ImportantDates = (monthIndex) => {
    return (
      <>
        {importantDates &&
          importantDates
            .filter((row) => months.indexOf(row.month) === monthIndex)
            .map((row, i) => (
              <ListItem key={i} className="py-0">
                <ListItemAvatar className="mb-3">
                  <Avatar>
                    <CalendarMonth />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${row.month} ${row.day !== 0 ? row.day : ""} `}
                  secondary={row.serviceDescription}
                />
              </ListItem>
            ))}
      </>
    );
  };

  return (
    <>
      <div className="container">
        {/* Alert */}
        <AlertService divisionCode={division.divisionAbbreviation} />

        {/* Services */}
        <DivisionServicesSmall divisionAbbreviation={division.divisionAbbreviation} includeHomepage={true} />

        {/* More Services */}
        <DivisionMoreServices divisionAbbreviation={division.divisionAbbreviation} />
      </div>

      {/* About */}
      <DivisionAboutBanner
        divisionAbbreviation={division.divisionAbbreviation}
        divisionTag={division.tag}
        linkTo="/tax-collector/about"
      />

      <div className="container">
        {/* Important Dates */}
        <div className="py-5">
          <Typography variant="h5">Upcoming Important Dates</Typography>
          <List>
            {importantDates && ImportantDates(currentMonthIndex)}
            {importantDates && ImportantDates(currentMonthIndex + 1)}
          </List>
          <Button variant="contained" color="primary" component={Link} to="/tax-collector/important-dates">
            View More Dates
          </Button>
        </div>

        {/* Location */}
        <div className="py-5">
          <Typography variant="h5" className="px-3 mb-3 px-md-0">
            Tax Collector Office Location
          </Typography>
          <div className="d-flex justify-content-center align-content-left">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1652.5579476570629!2d-117.2897791436554!3d34.066543205654625!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcad8248e6ba13%3A0x98750ba7c33ecac6!2sSan%20Bernardino%20County%20ATC!5e0!3m2!1sen!2sus!4v1733355077835!5m2!1sen!2sus"
              width="100%"
              height="390"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="ATC San Bernardino, California, USA"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>

        {/* Contact */}
        <DivisionContact
          divisionAbbreviation={division.divisionAbbreviation}
          sectionTitle={`Contact ${division.title}`}
        />
      </div>
    </>
  );
};

Home.propTypes = {
  division: PropTypes.object.isRequired,
};

export default Home;
