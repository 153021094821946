import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getDataFromCmsApi } from '../../api/contentful-service';
import LinkActionCard from '../action-card/link-action-card';
import { getColorByIndex, isExternalLink } from '../../utils/utils';

SubServicesContainer.propTypes = {
    parentServiceId: PropTypes.string.isRequired,
};

function SubServicesContainer({ parentServiceId, ...props }) {
    const [services, setServices] = useState([]);

    useEffect(() => {
        getServices();
    }, [parentServiceId]);

    const getServices = async () => {
        let result = await getDataFromCmsApi(`DivisionService/by-parent/${parentServiceId}`);
        setServices(result);
    };

    return (
        <div className="grid-container grid-style-4 py-5">
            {services && (
                services.map((service, index) => (
                    <LinkActionCard
                        key={index}
                        title={service.serviceLabel}
                        linkDestination={service.link}
                        cardColor={getColorByIndex(index)}
                        target={isExternalLink(service.link) ? "_blank" : ""}
                    />
                ))
            )}
        </div>
    );
}

export default SubServicesContainer;