import React from "react";
import { useCurrentRefinements } from "react-instantsearch-core";
import PropTypes from "prop-types";

const textColor = "#003366";

const CustomFilterNumber = ({ isFilterOpen, isColorInverted, ...props }) => {
    const { items } = useCurrentRefinements(props);

    if (!items.length) return null;

    const isTextWhite = !isColorInverted ? !isFilterOpen : isFilterOpen;
    const isBackgroundWhite = !isColorInverted ? isFilterOpen : !isFilterOpen;

    const filterCount = items.reduce((acc, item) => acc + item.refinements.length, 0);
    return filterCount === 0 ? null : (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: isTextWhite ? "white" : textColor,
                backgroundColor: isBackgroundWhite ? "white" : textColor,
                borderRadius: "50%",
                fontWeight: "bold",
                fontSize: "13px",
                width: "19px",
                height: "19px",
                margin: "0 1px",
            }}
        >
            {filterCount}
        </div>
    );
};

CustomFilterNumber.propTypes = {
    isFilterOpen: PropTypes.bool.isRequired,
    isColorInverted: PropTypes.bool,
};

export default CustomFilterNumber;