import React, { } from 'react';
import { default as MuiBreadcrumbs } from '@mui/material/Breadcrumbs';
import { Button, Typography } from '@mui/material';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { govHubSubPath } from '../utils/utils.js';

const convertBreadcrumb = string => {
    string = string
        .replace(/-/g, ' ')
        // .replace(/oe/g, 'ö')
        // .replace(/ae/g, 'ä')
        // .replace(/ue/g, 'ü') <- Revenue Recovery is not displayed correctly
    return string;
};

const Breadcrumbs = () => {
    const location = useLocation();
    let path = location.pathname;
    //check if path start with govHubSubPath
    if (path.startsWith('/' + govHubSubPath)) {
        const parts = path.split('/');
        if (parts.length > 3) {
            path = `/${parts.slice(1, 3).join('/')}`; 
        }
    }
    
    const breadcrumbs = path.split('/').filter((x) => x);

    return (
        <div className='container p-1'>
            <MuiBreadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}>
                <Button component={Link} to="/">
                    Home
                </Button>

                {breadcrumbs.map((name, index) => {
                    const routeTo = `/${breadcrumbs.slice(0, index + 1).join('/')}`;
                    const isLast = index === breadcrumbs.length - 1;
                    return isLast ? (
                        <Typography variant="button" key={name} color="text.secondary">
                            {convertBreadcrumb(name)}
                        </Typography>
                    ) : (
                        <Button component={Link} key={name} to={routeTo}>
                            {convertBreadcrumb(name)}
                        </Button>
                    );
                })}
            </MuiBreadcrumbs>
        </div>
    );
};

export default Breadcrumbs;