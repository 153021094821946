import { useEffect } from 'react';
import PropTypes from 'prop-types';



const ScriptLoader = ({ scriptSrc, scriptId, onLoad, onError }) => {
    useEffect(() => {
        // Check if the script is already loaded
        if (!document.getElementById(scriptId)) {
            const script = document.createElement('script');
            script.src = scriptSrc;
            script.async = true;
            script.id = scriptId;

            // Attach event listeners
            script.onload = () => {
                if (onLoad) onLoad();
            };

            script.onerror = () => {
                console.error(`Failed to load script ${scriptSrc}`);
                if (onError) onError();
            };

            document.body.appendChild(script);
        } else {
            if (onLoad) onLoad();
        }
    }, [scriptSrc, scriptId, onLoad, onError]);

    return null;
};

export default ScriptLoader;

ScriptLoader.propTypes = {
    scriptSrc: PropTypes.string.isRequired,
    scriptId: PropTypes.string.isRequired,
    onLoad: PropTypes.func,
    onError: PropTypes.func,

};
