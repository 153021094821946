import React, { useEffect, useState } from 'react';
import Loader from '../../components/loader';
import { getDataFromCmsApi } from '../../api/contentful-service';
import { parseHtml } from '../../utils/utils';
import AwardCard from '../../components/awards/award-card';
import AwardsBanner from './awards-banner';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

AwardsContainer.propTypes = {
  pageId: PropTypes.string.isRequired
};

function AwardsContainer({ pageId }) {
  const [awards, setAwards] = useState([]);
  const [awardsAwards, setAwardsAwards] = useState([]);
  const [awardsAchievements, setAwardsAchievements] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi(`webpage/${pageId}`);

    const awardsAwards = result.webpageItems.filter(award => award.secondaryDescription && award.secondaryDescription.includes('award'));

    const awardsAchievements = result.webpageItems.filter(award => award.secondaryDescription && award.secondaryDescription.includes('achievement'));

    setAwardsAwards(awardsAwards);
    setAwardsAchievements(awardsAchievements);

    setAwards(result);
    setIsLoading(false);
  };

  return isLoading ? (
    <Loader />
  ) : (
    awards && (
      <>
        <div>
          <div>{awardsAwards && awardsAwards.length > 0 && <AwardsBanner awards={awards} awardsAwards={awardsAwards} />}</div>

          <div>
            {awardsAchievements && awardsAchievements.length > 0 && (
              <div className="container mt-5 pb-5">
                <h2 style={{ marginBottom: 30 }}>Achievements</h2>
                <Grid container spacing={2} justifyContent="left" alignItems="start" className="mb-5">
                  {awardsAchievements.map((award, index) => (
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4} key={index}>
                      <AwardCard
                        icon={award.imageUrl}
                        title={award.title}
                        content={parseHtml(award.description)}
                        secondaryIcon={award.secondaryImageUrl}
                        secondaryContent={award.secondaryDescription}
                        link={award.link}
                        linkText={award.linkText}
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>
            )}
          </div>
        </div>
      </>
    )
  );
}

export default AwardsContainer;
