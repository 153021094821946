import React, { useEffect, useState } from "react";
import Loader from "../loader";
import PropTypes from "prop-types";
import LinkActionCard from "../action-card/link-action-card";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { getColorByIndex, isExternalLink } from "../../utils/utils";

const apiName = "DivisionService";

function DivisionServicesSmall({
  divisionAbbreviation,
  includeShortDescription = false,
  includeMoreServices = false,
  includeHomepage = false,
}) {
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getData().then(() => setIsLoading(false));
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi(`${apiName}/${divisionAbbreviation}`);

    const sortedAndFilteredServices = result
      .filter(
        (service) =>
          service.parentService.length === 0 &&
          (includeMoreServices || !service.isMoreServices) &&
          (includeHomepage || !service.isHomePage)
      )
      .sort((a, b) => a.sortOrder - b.sortOrder);
    setServices(sortedAndFilteredServices);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="py-5">
      <h1 className="display-4 text-center pb-3">Our Services</h1>

      <div className="grid-container grid-style-4 py-3">
        {services &&
          services.map((item, index) => (
            <LinkActionCard
              key={index}
              title={item.serviceLabel}
              linkDestination={item.link}
              cardColor={getColorByIndex(index)}
              target={isExternalLink(item.link) ? "_blank" : ""}
              shortDescription={includeShortDescription ? item.shortDescription : ""}
            />
          ))}
      </div>
    </div>
  );
}

DivisionServicesSmall.propTypes = {
  divisionAbbreviation: PropTypes.string.isRequired,
  includeShortDescription: PropTypes.bool,
  includeMoreServices: PropTypes.bool,
  includeHomepage: PropTypes.bool,
};

export default DivisionServicesSmall;
