import { useEffect, useState } from "react";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../utils/utils";
import StaticPage from "../../components/static-page/static-page";

const UnderstandingYourTaxBill = () => {
  const [understandingYourTaxBill, setunderstandingYourTaxBill] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi("webpage/sampleAnnualSecuredTaxBill");
    setunderstandingYourTaxBill(result);
  };

  return (
    understandingYourTaxBill && (
      <StaticPage title={understandingYourTaxBill.pageTitle}>
        <map name="FPMap0">
          <area
            shape="rect"
            coords="20, 248, 112, 282"
            title="This is your APN (Assessors Parcel Number), which is a unique number assigned to your property."
            alt="APN"
          />
          <area
            shape="rect"
            coords="20, 633, 145, 667"
            title="This is your APN (Assessors Parcel Number), which is a unique number assigned to your property."
            alt="APN"
          />
          <area
            shape="rect"
            coords="20, 901, 145, 933"
            title="This is your APN (Assessors Parcel Number), which is a unique number assigned to your property."
            alt="APN"
          />
          <area
            shape="rect"
            coords="112, 248, 177, 282"
            title="This is the unique tax bill number assigned to your property each year."
            alt="Tax Bill Number"
          />
          <area
            shape="rect"
            coords="144, 633, 269, 667"
            title="This is the unique tax bill number assigned to your property each year."
            alt="Tax Bill Number"
          />
          <area
            shape="rect"
            coords="144, 901, 269, 933"
            title="This is the unique tax bill number assigned to your property each year."
            alt="Tax Bill Number"
          />
          <area
            shape="rect"
            coords="177, 248, 247, 282"
            title="This is the tax rate area for your property.&nbsp; This is one of the components that determine the amount of taxes on your property."
            alt="Tax Rate Area"
          />
          <area
            shape="rect"
            coords="247, 248, 317, 282"
            title="This is the tax rate applied to your Tax Rate Area."
            alt="Tax Rate"
          />
          <area
            shape="rect"
            coords="20, 340, 159, 383"
            title="This is the situs or physical address of the property.&nbsp; (If there are no improvements to the property this may be blank)"
            alt="Property Address"
          />
          <area
            shape="rect"
            coords="20, 293, 159, 336"
            title="This is the legal owner(s) of the record as of January 1."
            alt="Legal Owner"
          />
          <area
            shape="rect"
            coords="176, 291, 316, 323"
            title="This is the date that the first installment of taxes is due."
            alt="First Installment Due Date"
          />
          <area
            shape="rect"
            coords="176, 323, 316, 354"
            title="This is the date that the second installment of taxes is due."
            alt="Second Installment Due Date"
          />
          <area
            shape="rect"
            coords="334, 888, 634, 934"
            title="Deadline to pay tax amount without penalty."
            alt="Deadline"
          />
          <area
            shape="rect"
            coords="334, 620, 634, 667"
            title="Deadline to pay tax amount without penalty."
            alt="Deadline"
          />
          <area
            shape="rect"
            coords="176, 354, 316, 379"
            title="This is the total tax amount - from the first and second installments - due and payable. This amount is valid if paid before the delinquent dates."
            alt="Total Tax Amount"
          />
          <area
            shape="rect"
            coords="20, 975, 270, 1018"
            title="This is the amount due if property taxes are paid after the deadline."
            alt="Amount Due After Deadline"
          />
          <area
            shape="rect"
            coords="20, 707, 270, 750"
            title="This is the amount due if property taxes are paid after the deadline."
            alt="Amount Due After Deadline"
          />
          <area
            shape="rect"
            coords="334, 292, 633, 535"
            title="This area shows the breakdown of your tax bill by agency receiving your tax dollars. If you have questions regarding any of the amounts, you should contact them directly."
            alt="Tax Bill Breakdown"
          />
          <area
            shape="rect"
            coords="20, 392, 318, 422"
            title="Legal description of the property."
            alt="Legal Description"
          />
          <area
            shape="rect"
            coords="20, 425, 318, 495"
            title="Additional notes regarding your tax bill or property."
            alt="Additional Notes"
          />
          <area
            shape="rect"
            coords="334, 192, 634, 288"
            title="This is the taxable value of your property."
            alt="Taxable Value"
          />
        </map>
        {parseHtml(understandingYourTaxBill.content)}
        <div>
          <img
            src={parseHtml(understandingYourTaxBill.contentImageUrl)}
            alt="understadning your tax bill"
            usemap="#FPMap0"
            width="650"
            height="1073"
            style={{ display: "block", "margin-left": "auto", "margin-right": "auto" }}
          />
        </div>
      </StaticPage>
    )
  );
};

export default UnderstandingYourTaxBill;
