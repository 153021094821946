import React, { useEffect, useState } from 'react';
import LinkActionCard from '../../components/action-card/link-action-card';
import AwardsContainer from '../../components/awards/awards-container';
import { getDataFromCmsApi } from '../../api/contentful-service';
import { parseHtml } from '../../utils/utils';
import { Box } from '@mui/material';
import { getColorByIndex, isExternalLink } from '../../utils/utils';

const InternalAudits = () => {
  const [data, setData] = useState([]);
  const [pageItems, setPageItems] = useState([]);
  const [usefulLinks, setUsefulLinks] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi('webpage/internalAudits');
    setData(result);

    const pageItems = result.webpageItems.filter(webpageItems => !webpageItems.secondaryDescription || !webpageItems.secondaryDescription.includes('usefulLink'));
    const usefulLinks = result.webpageItems.filter(webpageItems => webpageItems.secondaryDescription && webpageItems.secondaryDescription.includes('usefulLink'));

    setPageItems(pageItems);
    setUsefulLinks(usefulLinks);
  };

  return (
    data && (
      <div>
        <div className="container mt-5 pb-5">
          <h1 className="display-5 text-center pb-3 text-break mt-4">{data.pageTitle}</h1>
          <div>
            {pageItems &&
              pageItems.map((item, i) => (
                <Box sx={{ marginBottom: 4, fontSize: { xs: 'medium', sm: 'large' } }}>
                  <h2>{item.title}</h2>
                  <div>{parseHtml(item.description)}</div>
                </Box>
              ))}
          </div>
        </div>
        <div>
          <AwardsContainer pageId={'internalAuditsAwards'} />
        </div>
        <div className="container mt-5 pb-5">
          <h2>Useful Links</h2>
          <div className="grid-container grid-style-4 py-3">
            {usefulLinks &&
              usefulLinks.map((item, index) => (
                <LinkActionCard key={index} title={item.title} linkDestination={item.link} cardColor={getColorByIndex(index)} target={isExternalLink(item.link) ? '_blank' : ''} />
              ))}
          </div>
        </div>
      </div>
    )
  );
};

export default InternalAudits;
