import { useEffect, useState } from "react";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../utils/utils";
import StaticPage from "../../components/static-page/static-page";

const AchWirePayment = () => {
  const [achWirePayment, setAchWirePayment] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi("webpage/achWirePaymentInstructions");
    setAchWirePayment(result);
  };

  return (
    achWirePayment && (
      <StaticPage title={achWirePayment.pageTitle}>{parseHtml(achWirePayment.content)}</StaticPage>
    )
  );
};

export default AchWirePayment;
