import AlertService from "../../components/alert-service/alert-service";
import DivisionAboutBanner from "../../components/division-homepage/division-about-banner";
import DivisionContact from "../../components/division-homepage/division-contact";
import LinkActionCard from "../../components/action-card/link-action-card";
import { useEffect, useState } from "react";
import { getDataFromCmsApi } from "../../api/contentful-service";
import PropTypes from "prop-types";
import { getColorByIndex, isExternalLink } from "../../utils/utils";
import { Typography } from "@mui/material";

function PropertyTaxHome({ division }) {
  const [serviceTiles, setServicesTiles] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = (await getDataFromCmsApi(`DivisionService/${division.divisionAbbreviation}`))
      .filter((service) => !service.isMoreServices)
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .reduce((acc, item) => {
        (acc[item.propertyTaxCategory] = acc[item.propertyTaxCategory] || []).push(item);
        return acc;
      }, {});

    setServicesTiles(result);
  };

  return (
    <>
      <div className="container">
        <AlertService divisionCode={division.divisionAbbreviation} />

        {/* Services */}
        <div className="py-5">
          <Typography variant="h2" className="text-center">
            Our Services
          </Typography>

          {/* different tile display so won't be using the shared component DivisionServicesSmall */}
          {serviceTiles &&
            Object.keys(serviceTiles).map((category, index) => (
              <div key={index} className="py-5">
                <p
                  className="fw-bold text-center text-lg-start pb-4 pb-md-0"
                  style={{ color: "#626468", fontSize: "1.6rem", marginBottom: "-10px" }}
                >
                  {category === "null" ? "Other" : category}
                </p>
                <hr className="d-none d-md-block" />
                <div key={index} className="grid-container grid-style-4 pt-1">
                  {serviceTiles[category].map((item, i) => (
                    <LinkActionCard
                      key={i}
                      title={item.serviceLabel}
                      linkDestination={item.link}
                      cardColor={getColorByIndex(index + i * 2)}
                      target={isExternalLink(item.link) ? "_blank" : ""}
                    />
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
      <div>
        <DivisionAboutBanner
          divisionAbbreviation={division.divisionAbbreviation}
          divisionTag={division.tag}
          linkTo="/property-tax/about"
        />
        <DivisionContact
          divisionAbbreviation={division.divisionAbbreviation}
          sectionTitle={"Contact Property Tax"}
        />
      </div>
    </>
  );
}

PropertyTaxHome.propTypes = {
  division: PropTypes.shape({
    divisionAbbreviation: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
  }).isRequired,
};

export default PropertyTaxHome;
