import React, { useEffect } from "react";
import { useHits, useSearchBox } from "react-instantsearch";

import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { parseHtml } from "../../utils/utils";
import PropTypes from "prop-types";
import CopyHashLinkButton from "../../components/copy-hash-link-button";
import "./faq.scss";

const primaryTextColor = "#003366";
const backgroundColorLight = "#f9f9fa";

const CustomAccordion = styled(Accordion)(() => {
  return {
    boxShadow: "none",
    border: "none",
    "&::before": {
      display: "none",
    },
  };
});

const CustomHighlight = ({ attribute, hit }) => {
  const highlightValue = hit._highlightResult[attribute].value;
  const parsedHTML = parseHtml(highlightValue);

  return <>{parsedHTML}</>;
};

CustomHighlight.propTypes = {
  attribute: PropTypes.string,
  hit: PropTypes.object,
};

const FaqCustomHits = ({ setHits, setIsQueryEmpty, ...props }) => {
  const { results } = useHits(props);
  const { hits } = results;

  const { query } = useSearchBox();
  const isQueryEmpty = !query || query.trim() === "";

  useEffect(() => {
    if (!results) return null;

    setHits(hits);
    setIsQueryEmpty(isQueryEmpty);
  }, [hits, isQueryEmpty, setHits, setIsQueryEmpty]);

  if (!hits.length) {
    return <Typography variant="h6">No results found</Typography>;
  }

  const groupedByFaqType = hits.reduce((acc, { typeName, typeDescription, ...rest }) => {
    if (!acc[typeName]) {
      acc[typeName] = {
        description: typeDescription,
        items: [],
      };
    }
    acc[typeName].items.push({ typeName, typeDescription, ...rest });
    return acc;
  }, {});

  return (
    <div>
      {Object.keys(groupedByFaqType).map((type) => {
        const formattedId = type.trim().replace(/\s+/g, "-");

        return (
          <div id={formattedId} key={type} className="faq-text pb-5 mb-3">
            <div className="d-flex align-items-center" style={{ columnGap: "0.7rem" }}>
              <h4 style={{ color: primaryTextColor }}>{type}</h4>
              <CopyHashLinkButton elementId={formattedId} iconSize={18} />
            </div>
            <div className="fs-6 mb-2">{parseHtml(groupedByFaqType[type].description)}</div>

            {groupedByFaqType[type].items.map((hit, i) => (
              <div id={hit.objectID} key={i} className="py-1">
                <CustomAccordion
                  defaultExpanded={hit.objectID === window.location.hash.replace("#", "")}
                  style={{ backgroundColor: backgroundColorLight, borderRadius: 10 }}
                  onChange={(e, expanded) => {
                    if (expanded) {
                      window.history.replaceState(null, "", `#${hit.objectID}`);
                    }
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <div
                      className="d-flex m-0"
                      style={{ color: primaryTextColor, columnGap: "0.45rem", fontSize: "18px" }}
                    >
                      <CustomHighlight attribute="question" hit={hit} />
                      {/* <CopyHashLinkButton elementId={hit.objectID} iconSize={15} /> */}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className="d-flex flex-column pt-0" style={{ fontSize: "16px" }}>
                    <CustomHighlight attribute="answer" hit={hit} />
                  </AccordionDetails>
                </CustomAccordion>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

FaqCustomHits.propTypes = {
  setHits: PropTypes.func,
  setIsQueryEmpty: PropTypes.func,
};

export default FaqCustomHits;
