import AlertService from "../../components/alert-service/alert-service";
import DivisionAboutBanner from "../../components/division-homepage/division-about-banner";
import DivisionContact from "../../components/division-homepage/division-contact";
import PropTypes from "prop-types";
import DivisionServicesSmall from "../../components/division-homepage/division-services-small";
import DivisionMoreServices from "../../components/division-homepage/division-more-services";

function AuditorHome({ division }) {
  return (
    <>
      <div className="container">
        <AlertService divisionCode={division.divisionAbbreviation} />
        <DivisionServicesSmall
          divisionAbbreviation={division.divisionAbbreviation}
          includeHomepage={true}
          includeShortDescription={true}
        />
        <DivisionMoreServices divisionAbbreviation={division.divisionAbbreviation} />
      </div>

      <DivisionAboutBanner
        divisionAbbreviation={division.divisionAbbreviation}
        divisionTag={division.tag}
        linkTo="/internal-audits/about"
      />

      <DivisionContact
        divisionAbbreviation={division.divisionAbbreviation}
        sectionTitle={"Contact Internal Audits Division"}
      />
    </>
  );
}

AuditorHome.propTypes = {
  division: PropTypes.shape({
    divisionAbbreviation: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
  }).isRequired,
};

export default AuditorHome;
