import { useEffect, useRef, useState } from "react";
import { Translate } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import "./g-translate.scss";

const GTranslate = () => {
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null); // Ref to track the dropdown

    // Initialize GTranslate Translate
    const gTranslateElementInit = () => {
        new window.g.translate.TranslateElement(
            {
                default_language: "en",
                detect_browser_language: true,
                wrapper_selector: ".gtranslate_wrapper",
                horizontal_position: "right",
                vertical_position: "top",
            },
            "g_translate_element"
        );
    };

    useEffect(() => {
        // Check if the script is already added
        const existingScript = document.getElementById("g-translate-script");
        if (!existingScript) {
            const script = document.createElement("script");
            script.id = "g-translate-script";
            script.src = "//cdn.gtranslate.net/widgets/latest/dropdown.js";
            script.async = true; // Ensure the script loads asynchronously
            document.body.appendChild(script);
            // Define the callback function
            window.gTranslateElementInit = gTranslateElementInit;
        }
    }, []);

    const toggleDropdown = () => {
        setDropdownVisible(!isDropdownVisible);
        // Wait for the dropdown to render before attempting to click the select element

        // Wait for the dropdown to render before attempting to click the select element
        setTimeout(() => {
            const languageSelector = document.querySelector(".gt_selector");
            if (languageSelector) {
                // Trigger focus and mousedown events
                //   languageSelector.dispatchEvent(new Event('focus', { bubbles: true }));
                // languageSelector.dispatchEvent(new MouseEvent('mousedown', { bubbles: true }));

                // Trigger click event
                languageSelector.click();
            } else {
                console.error("Language selector not found.");
            }

        }, 500)

    }

    const handleClickOutside = (event) => {
        // Hide dropdown if clicking outside the dropdownRef
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        // Add event listener to detect clicks outside the dropdown
        document.addEventListener("mousedown", handleClickOutside);

        // Cleanup event listener on unmount
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div style={{ position: "relative" }} className="d-flex d-md-block" ref={dropdownRef}>
            {/* Icon to toggle the dropdown */}
            <IconButton
                onClick={toggleDropdown}
                title="Translate"
                sx={{
                    color: "white",
                    "&:hover": {
                        color: "#cd9e0c",
                    },
                }}
            >
                <Translate sx={{
                    fontSize: {
                        xs: 20, // small size for extra-small screens
                        sm: 25, // medium size for small screens
                        md: 20, // large size for medium screens
                        lg: 25, // extra-large size for large screens
                        xl: 30  // extra-extra-large size for extra-large screens
                    }
                }} />

            </IconButton>

            {/* Dropdown wrapper */}
            <div
                id="g_translate_element"
                className={`gtranslate_wrapper ${isDropdownVisible ? "visible" : "invisible"}`}
            ></div>
        </div>
    );
};

export { GTranslate };
