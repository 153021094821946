import React, { useEffect } from "react";
import { getIsAuthenticated, getSecondaryToken, signIn } from '../../services/auth';
import PropTypes from 'prop-types';
import scrollToTop from "../../utils/scroll-to-top-util";
import { govHubSubPath } from '../../utils/utils.js';
import { useGAContext } from "../../_contexts/ga-context/ga-context.js";
import { loadGSGScript } from "../../services/load-gsg-scripts.js";

GSGWidget.propTypes = {
    initialPath: PropTypes.string,
    triggerRerender: PropTypes.bool
};

const rootIframeUrl = process.env.REACT_APP_ROOTIFRAMEURL;

function GSGWidget({ initialPath, triggerRerender, ...props }) {

    const [language, setLanguage] = React.useState("en");
    const [isDropdownRendered, setIsDropdownRendered] = React.useState(false);
    const [GATag, setGATag] = React.useState('');
    const consentGiven = useGAContext();

    useEffect(() => {
        const languageDropdown = document.getElementsByClassName("gt_selector")[0];
        if (languageDropdown) {
            setIsDropdownRendered(true);
            languageDropdown.addEventListener('change', handleLanguageDropdownChange);
            handleLanguageDropdownChange(); // Ensure initial load checks language
        } else {
            initializeGSG();
        }

        return () => {
            const dropdown = document.getElementsByClassName("gt_selector")[0];
            if (dropdown) {
                dropdown.removeEventListener('change', handleLanguageDropdownChange);
            }
        };
    }, []);

    useEffect(() => {
        if (isDropdownRendered) {
            initializeGSG();
        }
    }, [isDropdownRendered]);

    useEffect(() => {
        initializeGSG();
    }, [initialPath, triggerRerender, language, consentGiven]);

    useEffect(() => {
        if (consentGiven.consentGiven) {
            setGATag(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
        } else {
            setGATag('');
        }
    }, [consentGiven]);

    const handleUpdateURL = (newPath) => {
        newPath = '/' + govHubSubPath + newPath;
        window.history.replaceState(null, '', newPath);
    };

    const handleLanguageDropdownChange = async () => {
        const selectedValue = document.getElementsByClassName("gt_selector")[0].value.substring(5);
        selectedValue === "es" ? setLanguage("es") : setLanguage("en");
    };

    const initializeGSG = async () => {
        try {
            await loadGSGScript(process.env.REACT_APP_GSG_WIDGET_SCRIPT, "GsgPublicSite"); // Load the script and wait for GSGWidget to initialize
            attachGSGPublicSite();
        } catch (error) {
            console.error("Error initializing GSGWidget:", error);
        }
    };

    const attachGSGPublicSite = () => {
        // console.log("attach GSG Public Site");
        window.GsgPublicSite.attach({
            site: "ca-sanbernardino/ca-sanbernardino",
            rootIframeUrl: rootIframeUrl,
            initialPath: initialPath,
            language: language,
            googleAnalytics: {
                googleTagId: GATag,
                userId: "",
            },
            elementSelector: "#gsg-embedded-public-site",
            onPathChange: (newPath) => {
                handleUpdateURL(newPath);
                scrollToTop();
            },
            getJwt: () => {
                return getIsAuthenticated().then(isAuthenticated => {
                    if (isAuthenticated) {
                        return getSecondaryToken().then(token => token);
                    }
                });
            },
            handleLogin: () => {
                signIn();
            }
        });
    }

    return (
        <div className="gsg-container">
            <div className="container mt-5">
                <div className="row">
                    <div id="gsg-embedded-public-site"></div>
                </div>
            </div>
        </div>
    );

}

export default GSGWidget;
